import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    // meta: {
    //   title: '首页88888',
    // }
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // },
  {
    path: '/smartCity',
    name: 'smartCity',
    component: () => import(/* webpackChunkName: "about" */ '../views/productService/smartCity'),
    // meta: {
    //   title: '首页',
    // }
  },
  {
    path: '/smartPolice',
    name: 'smartPolice',
    component: () => import(/* webpackChunkName: "about" */ '../views/productService/smartPolice'),
    // meta: {
    //   title: '首页111',
    // }
  },
  {
    path: '/smartTraffic',
    name: 'smartTraffic',
    component: () => import(/* webpackChunkName: "about" */ '../views/productService/smartTraffic'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/AI',
    name: 'AI',
    component: () => import(/* webpackChunkName: "about" */ '../views/artificialIntelligence'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/blockchain',
    name: 'blockchain',
    component: () => import(/* webpackChunkName: "about" */ '../views/blockchain'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/economicBase',
    name: 'economicBase',
    component: () => import(/* webpackChunkName: "about" */ '../views/economicBase'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/joinUS',
    name: 'joinUS',
    component: () => import(/* webpackChunkName: "about" */ '../views/joinUS'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/companyIntroduction',
    name: 'companyIntroduction',
    component: () => import(/* webpackChunkName: "about" */ '../views/companyIntroduction'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/internet',
    name: 'internet',
    component: () => import(/* webpackChunkName: "about" */ '../views/internet'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/energy',
    name: 'energy',
    component: () => import(/* webpackChunkName: "about" */ '../views/energy'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/community',
    name: 'community',
    component: () => import(/* webpackChunkName: "about" */ '../views/community'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/manufacturing',
    name: 'manufacturing',
    component: () => import(/* webpackChunkName: "about" */ '../views/manufacturing'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/Data',
    name: 'Data',
    component: () => import(/* webpackChunkName: "about" */ '../views/Data'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/enterpriseServices',
    name: 'enterpriseServices',
    component: () => import(/* webpackChunkName: "about" */ '../views/enterpriseServices'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/fireControl',
    name: 'fireControl',
    component: () => import(/* webpackChunkName: "about" */ '../views/fireControl'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/business',
    name: 'business',
    component: () => import(/* webpackChunkName: "about" */ '../views/business'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/case',
    name: 'case',
    component: () => import(/* webpackChunkName: "about" */ '../views/case'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/formwork',
    name: 'formwork',
    component: () => import(/* webpackChunkName: "about" */ '../views/formwork'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/dataCenter',
    name: 'dataCenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/dataCenter'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/businessCenter',
    name: 'businessCenter',
    component: () => import(/* webpackChunkName: "about" */ '../views/businessCenter'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/trustedService',
    name: 'trustedService',
    component: () => import(/* webpackChunkName: "about" */ '../views/trustedService'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/SMSgateway',
    name: 'SMSgateway',
    component: () => import(/* webpackChunkName: "about" */ '../views/SMSgateway'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/safeCommunity',
    name: 'safeCommunity',
    component: () => import(/* webpackChunkName: "about" */ '../views/safeCommunity'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/integrated',
    name: 'integrated',
    component: () => import(/* webpackChunkName: "about" */ '../views/integrated'),
    // meta: {
    //   title: '首页88888',
    // }
  },
  {
    path: '/economics',
    name: 'economics',
    component: () => import(/* webpackChunkName: "about" */ '../views/economics'),
    // meta: {
    //   title: '首页88888',
    // }
  },
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})


export default router
