<template>
<!-- <div class="home" ref="home" @scroll="handleScroll"> -->
<div class="home" ref="home">
    <!-- 头部导航 -->
    <Nav />

    <!-- 轮播 -->
    <el-carousel indicator-position="outside" height="620px">
        <el-carousel-item>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_swiper1.png" alt="" />
            <div class="swiperMain">
                <h2 style="color: #ffffff">专业技术服务商与研发商</h2>
                <p style="color: #ffffff">
                    提供专业的互联网技术服务解决方案。拥有资深的技术团队、职业售后的团队与行业大牛，致力为企业提供专业化信息服务。
                </p>
                <el-button type="" @click="consult">立即咨询</el-button>
            </div>
        </el-carousel-item>
        <el-carousel-item>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_swiper2.png" alt="" />
            <div class="swiperMain">
                <h2>智慧城市</h2>
                <p>
                    提供智慧城市专业解决方案，基于信息技术帮助政府客户构建智慧城市标准规范顶层设计、智慧应用、数据共享等全周期服务、整合生态资源，优化城市功能，提高市民生活质量。
                </p>
                <el-button type="" @click="consult">立即咨询</el-button>
            </div>
        </el-carousel-item>
        <el-carousel-item>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_swiper3.png" alt="" />
            <div class="swiperMain">
                <h2 style="color: #ffffff">智慧公安</h2>
                <p style="color: #ffffff">
                    智慧公安建设依托公安云计算中心、公安应用平台和数据库，结合云计算、物联网、卫星定位、视频监控等多种先进技术，以移动信息化为辅助作为创新警务模式、提升执法效能、已达到多警种联动和提升队伍管理水平的强大推动力，帮助公安部门在应对各种复杂局面时作出更智慧的决策，提升公安机关工作的层次、质量和水平。
                </p>
                <el-button type="" @click="consult">立即咨询</el-button>
            </div>
        </el-carousel-item>
        <el-carousel-item>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_swiper4.png" alt="" />
            <div class="swiperMain">
                <h2 style="color: #ffffff">智慧交通</h2>
                <p style="color: #ffffff">
                    运用物联网效能、云计算、人工智能、自动控制、移动互联网等技术，对信号机进行联合控制，实现对路口信号控制的全局掌控，在区域、城市甚至更大的时空范围具备感知、互联、分析、预测、控制等能力，以充分保障交通安全、发挥交通基础设施效能、提升交通系统运行效率和管理水平，为通畅的公众出行和可持续的经济发展服务。
                </p>
                <el-button type="" @click="consult">立即咨询</el-button>
            </div>
        </el-carousel-item>
    </el-carousel>

    <!-- 公司业务信息 -->
    <div class="BusinessInfo">
        <div class="infoMain">
            <div class="mainItem">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_fuwu.png" alt="" />
                <div>
                    <h2>
                        <numberGrow :value="num1" :time="10"></numberGrow>
                        +
                    </h2>
                    <p>服务用户</p>
                </div>
            </div>
            <div class="mainItem">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_dingzhi.png" alt="" />
                <div>
                    <h2>
                        <numberGrow :value="num2" :time="10"></numberGrow>
                        +
                    </h2>
                    <p>定制化解决方案</p>
                </div>
            </div>
            <div class="mainItem">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_lingyu.png" alt="" />
                <div>
                    <h2>
                        <numberGrow :value="num3" :time="10"></numberGrow>
                        +
                    </h2>
                    <p>涉及领域</p>
                </div>
            </div>
            <div class="mainItem">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_rongyu.png" alt="" />
                <div>
                    <h2>
                        <numberGrow :value="num4" :time="10"></numberGrow>
                        +
                    </h2>
                    <p>荣誉资质</p>
                </div>
            </div>
        </div>
    </div>

    <!-- 产品服务 -->
    <div class="productService">
        <h2 class="ENtitle">PRODUCT SERVICE</h2>
        <h2 class="CNtitle">产品服务</h2>
        <div class="serviceMain">
            <div class="service_1 bd1 serviceItem">
                <div>
                    <h3>智慧城市</h3>
                    <p>
                        打通城市系统，提升资源运行效率，优化城市管理服务，利用信息通信技术
                        (ICT)
                        提高运营效率，与公众共享信息，并提供更优质的政府服务和公民福利。
                    </p>
                    <el-button>
                        <a href="/smartCity"> 了解详情 </a>
                    </el-button>
                </div>
            </div>
            <div class="service_2 bd1 serviceItem">
                <div>
                    <h3>飞报大师</h3>
                    <p class="secTitle">让团队管理更轻松</p>
                    <p>
                        以项目为核心，提炼日常工作汇报流程并结合项目管理体系与人员管理体系，提升管理能力。
                    </p>
                    <el-button @click="gotoopen('https://www.flyreport.cn')">
                        了解详情 
                    </el-button>
                </div>
            </div>
            <div class="service_3">
                <div class="service_3_top">
                    <div class="bd1 serviceItem mr20">
                        <div>
                            <h3>云智联信</h3>
                            <p>提供多种通讯功能,全面满足业务需求</p>
                            <el-button @click="gotoopen('http://sms.timespark.cn')">
                                了解详情 
                            </el-button>
                        </div>
                    </div>
                    <div class="bd1 serviceItem">
                        <div>
                            <h3>智慧交通</h3>
                            <p>AI赋能交通,让出行更通畅、规划更智能</p>
                            <el-button>
                                <a href="/smartTraffic"> 了解详情 </a>
                            </el-button>
                        </div>
                    </div>
                </div>
                <div class="service_3_bot bd1 serviceItem">
                    <div>
                        <h3>智慧公安</h3>
                        <p>
                            以人工智能、大数据等技术结合公安机关实战业务需求，以提升能效为驱动，构建智慧公安解决方案。
                        </p>
                        <el-button>
                            <a href="/smartPolice"> 了解详情 </a>
                        </el-button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- 研发服务 -->
    <div class="RDservice">
        <h2 class="ENtitle">R & D SERVICE</h2>
        <h2 class="CNtitle">研发服务</h2>
        <p>
            通过与100+企业的深度合作，获得良好的用户口碑，无论是开发速度，还是用户体验，都获得用户高度的认可。
        </p>
        <div class="RDmain">
            <div class="RDitem">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_xitong.png" alt="" />
                <h3>系统集成</h3>
                <p>
                    拥有专业的软件开发团队，团队成员来自各大知名上市企业，具有丰富的物联网、大数据、云计算、人工智能方面的系统集成经验。
                </p>
            </div>
            <div class="RDitem">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_yidong.png" alt="" />
                <h3>移动APP开发</h3>
                <p>
                    积累了大量移动应用APP开发，拥有优秀的APP开发团队。主要包括ios应用开发，android应用开发。
                </p>
            </div>
            <div class="RDitem">
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_ruanjian.png" alt="" />
                <h3>软件定制开发</h3>
                <p>
                    为用户提供高端的软件定制服务，通过专业的软件开发团队和规范管理流程，控制软件开发成本和进度，满足各大行业不同的软件需求（主要企业OA定制、信息系统定制、营销系统定制等)。
                </p>
            </div>
        </div>
    </div>

    <!-- 行业解决方案 -->
    <div class="IndustrySolu">
        <h2 class="ENtitle">INDUSTRY SOLUTIONS</h2>
        <h2 class="CNtitle">行业解决方案</h2>
        <div class="soluMain">
            <div :class="{ test: true, testenter: testShow }">
                <a href="/business">电子商务</a>
            </div>
            <div :class="{ test: true, testenter: testShow }">
                <a href="https://www.51eagle.com/home" target="_blank">教育</a>
            </div>
            <div :class="{ test: true, testenter: testShow }">
                <a href="/fireControl">消防</a>
            </div>
            <div :class="{ test: true, testenter: testShow }">
                <a href="/enterpriseServices">企业服务</a>
            </div>
            <div :class="{ test: true, testenter: testShow }">
                <a href="/smartCity">政务</a>
            </div>
            <div :class="{ test2: true, testenter: testShow }">
                <a href="/energy">能源</a>
            </div>
            <div :class="{ test2: true, testenter: testShow }">
                <a href="/community">社区</a>
            </div>
            <div :class="{ test2: true, testenter: testShow }">
                <a href="/smartPolice">公安</a>
            </div>
            <div :class="{ test2: true, testenter: testShow }">
                <a href="/manufacturing">制造</a>
            </div>
            <div :class="{ test2: true, testenter: testShow }">......</div>
        </div>
    </div>

    <!-- 技术引领时代 -->
    <div class="technologyTime">
        <h2 class="ENtitle">TECHNOLOGY LEADS THE TIMES</h2>
        <h2 class="CNtitle">技术引领时代</h2>
        <div class="timeItem">
            <div>
                <a href="/blockchain">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/区块链.png" alt="" />
                    <p>区块链</p>
                </a>
            </div>
            <div>
                <a href="/Data">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/大数据.png" alt="" />
                    <p>大数据</p>
                </a>
            </div>
            <div>
                <a href="/internet">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/物联网.png" alt="" />
                    <p>物联网</p>
                </a>
            </div>
            <div>
                <a href="/AI">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/智能.png" alt="" />
                    <p>人工智能</p>
                </a>
            </div>
        </div>
    </div>

    <!-- 我们优势 -->
    <div class="advantage">
        <h2 class="ENtitle">OUR ADVANTAGE</h2>
        <h2 class="CNtitle">我们的优势</h2>
        <div class="advantageMain">
            <div>
                <div class="advantageItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/优势1.png" alt="" />
                    <h2>专业可靠的项目团队</h2>
                    <p>十多年开发经验的专业团队，精准把控整个开发流程。</p>
                </div>
                <div class="advantageItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/优势2.png" alt="" />
                    <h2>成熟的项目落地方案</h2>
                    <p>
                        已为上百家客户提供专业解决方案，遵循全球公认的ISO质量体系，精细打磨每一个细节。
                    </p>
                </div>
                <div class="advantageItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/优势3.png" alt="" />
                    <h2>全面的服务体系</h2>
                    <p>提供完善的售后服务、增值服务、配置服务等特色服务。</p>
                </div>
                <div class="advantageItem">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/优势4.png" alt="" />
                    <h2>高新技术创新</h2>
                    <p>
                        拥有前沿的高新技术支撑企业解决方案，涉及区块链、大数据、人工智能等多个领域。
                    </p>
                </div>
            </div>
        </div>
    </div>

    <!-- 服务流程 -->
    <div class="serviceProcess">
        <h2 class="ENtitle">SERVICE PROCESS</h2>
        <h2 class="CNtitle">服务流程</h2>
    </div>

    <!-- 合作伙伴 -->
    <div class="partner">
        <h2 class="ENtitle">PARTENR</h2>
        <h2 class="CNtitle">合作伙伴</h2>
        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/合作伙伴.png" alt="" />
    </div>

    <!-- <Side /> -->

    <Footer ref="Footer" />
</div>
</template>

<script>
// @ is an alias to /src
import Footer from "@/components/footer.vue";
import Nav from "@/components/nav.vue";
import Side from "@/components/side.vue";
import numberGrow from "@/components/numAdd.vue";

export default {
    name: "Home",
    components: {
        Footer,
        Nav,
        Side,
        numberGrow,
    },
    data() {
        return {
            num1: 2000,
            num2: 12,
            num3: 12,
            num4: 12,

            currentScroll: 0,
            testShow: false,
        };
    },
    created() {
        document.documentElement.scrollTop = 0;
        document.body.scrollTop = 0;
    },
    mounted() {
        window.addEventListener("scroll", this.handleScroll);
    },
    methods: {
        // 头部导航切换
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
        handleScroll() {
            this.currentScroll = window.pageYOffset;
            // console.log(this.currentScroll);
            if (this.currentScroll >= 1700) {
                this.testShow = true;
            } else {
                this.testShow = false;
            }
        },
        consult() {
            this.$refs.Footer.checkShow(2)
        },
        gotoopen(url){
            window.open(url)
        }
    },
};
</script>

<style lang="scss" scoped>
.testenter {
    transform: translateX(0) !important;
    opacity: 1 !important;
    transition: all 2s ease;
}

.test {
    transform: translateX(-350px);
    opacity: 0;
}

.test2 {
    transform: translateX(350px);
    opacity: 0;
}

a {
    color: #fff;
    text-decoration: none;
}

.swiperMain {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    position: absolute;
    top: 0%;
    left: 200px;
    height: 100%;
    padding: 100px 0;
    box-sizing: border-box;

    h2 {
        font-size: 60px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #1b1b1b;
        line-height: 69px;
        text-align: left;
    }

    p {
        width: 811px;
        font-size: 22px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #1d1d1d;
        line-height: 33px;
        text-align: left;
    }

    .el-button {
        width: 224px;
        height: 58px;
        background: #fb5604;
        border-radius: 8px;
        border: none;
        font-size: 24px;
        font-weight: 500;
        color: #ffffff;
        line-height: 31px;
    }
}

.home {
    display: flex;
    flex-direction: column;

    .el-carousel__item {
        img {
            width: 100%;
            height: 100%;
        }
    }

    .el-carousel__item:nth-child(2n) {
        background-color: #99a9bf;
    }

    .el-carousel__item:nth-child(2n + 1) {
        background-color: #d3dce6;
    }

    ::v-deep .el-carousel__indicators--horizontal {
        bottom: 50px;
    }

    // 公司业务信息
    .BusinessInfo {
        display: flex;
        justify-content: center;
        height: 150px;
        background-color: #fff;
        box-shadow: 0px 6px 7px 0px rgba(113, 113, 113, 0.21);
        width: 85%;
        position: relative;
        top: -50px;
        left: 7.5%;
        z-index: 999;

        .infoMain {
            width: 90%;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .mainItem {
                display: flex;
                align-items: center;
                height: 100px;
                text-align: left;

                img {
                    width: 57px;
                    height: 57px;
                    margin-right: 20px;
                }

                h2 {
                    font-size: 32px;
                    font-weight: bold;
                    color: #000000;
                    margin-bottom: 10px;
                }

                p {
                    font-size: 24px;
                    font-weight: 400;
                    color: #000000;
                }
            }
        }
    }

    // 产品服务

    .productService {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 80px;

        .serviceMain {
            display: flex;
            justify-content: space-around;
            width: 85%;

            >div {
                height: 420px;
            }

            .serviceItem {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 385px;
                box-sizing: border-box;

                div {
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    width: 80%;
                    text-align: left;

                    p {
                        margin: 20px 0;
                    }

                    .el-button {
                        align-self: center;
                        border: 1px solid #fa5605;

                        a {
                            color: #000000;
                        }
                    }
                }

                h3 {
                    color: #202020;
                    font-weight: 500;
                    font-size: 24px;
                }

                p {
                    font-size: 16px;
                    font-weight: 400;
                    color: #717171;
                    line-height: 31px;
                }

                .secTitle {
                    margin-bottom: 0;
                }
            }

            .serviceItem:hover .el-button  {
                color: #fa5605;
            }

            .serviceItem:hover .el-button a {
                color: #fa5605;
            }

            .serviceItem:hover {
                background-color: #fa5605;
            }

            .serviceItem:hover p,
            .serviceItem:hover h3 {
                color: #fff;
            }

            .service_1 .el-button,
            .service_2 .el-button {
                margin-top: 80px;
            }

            .service_2 {
                margin: 0 20px;
            }

            .service_3 {
                .service_3_top {
                    display: flex;
                    justify-content: space-between;
                    margin-bottom: 20px;

                    >div {
                        width: 385px;
                        height: 200px;
                    }
                }

                .service_3_bot {
                    height: 200px;
                    width: 100%;
                }
            }
        }
    }

    // 研发服务
    .RDservice {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #8992a2;
        height: 630px;
        padding-top: 40px;
        background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_yanfafuwu.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        .CNtitle,
        >p {
            color: #fff;
        }

        >p {
            font-size: 16px;
            line-height: 30px;
        }

        .RDmain {
            display: flex;
            justify-content: space-around;
            width: 90%;
            margin-top: 80px;

            .RDitem {
                width: 400px;
                // height: 310px;
                background-color: #fff;
                padding: 40px;
                box-sizing: border-box;

                h3 {
                    font-size: 24px;
                    color: #202020;
                    font-weight: 400;
                    margin: 20px 0 40px 0;
                }

                p {
                    line-height: 30px;
                    color: #717171;
                    text-align: left;
                }
            }

            .RDitem:nth-child(1) {
                img {
                    width: 63px;
                    height: 53px;
                }
            }

            .RDitem:nth-child(2) {
                img {
                    width: 38px;
                    height: 53px;
                }
            }

            .RDitem:nth-child(3) {
                img {
                    width: 57px;
                    height: 53px;
                }
            }
        }
    }

    // 行业解决方案
    .IndustrySolu {
        padding: 40px 0;

        .soluMain {
            height: 780px;
            background-image: url('http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/home_yuan.png');
            background-repeat: no-repeat;
            background-size: 100% 100%;
            margin-bottom: 100px;
            position: relative;

            >div {
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 50%;
                position: absolute;
                font-size: 24px;
                font-family: Source Han Sans CN;
                font-weight: 400;
                color: #ffffff;
                line-height: 37px;

                a {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 100%;
                }
            }

            >div:nth-child(2n) {
                background: #497acf;
            }

            >div:nth-child(2n + 1) {
                background: #ff884d;
            }

            // @keyframes first {
            //     50% {
            //         width: 120px;
            //         height: 120px;
            //     }
            // }

            >div:nth-child(1) {
                width: 115px;
                height: 115px;
                top: 700px;
                left: 863px;
                animation: first 2s ease-in-out infinite alternate;
            }

            // @keyframes second {
            //     50% {
            //         width: 95px;
            //         height: 95px;
            //     }
            // }

            >div:nth-child(2) {
                width: 90px;
                height: 90px;
                top: 600px;
                left: 620px;
                // animation: second 2s ease-in-out infinite alternate;
            }

            // @keyframes third {
            //     50% {
            //         width: 105px;
            //         height: 105px;
            //     }
            // }

            >div:nth-child(3) {
                width: 100px;
                height: 100px;
                top: 400px;
                left: 460px;
                // animation: third 2s ease-in-out infinite alternate;
            }

            // @keyframes fourth {
            //     50% {
            //         width: 140px;
            //         height: 140px;
            //     }
            // }

            >div:nth-child(4) {
                width: 135px;
                height: 135px;
                top: 170px;
                left: 587px;
                // animation: fourth 2s ease-in-out infinite alternate;
            }

            // @keyframes fifth {
            //     50% {
            //         width: 110px;
            //         height: 110px;
            //     }
            // }

            >div:nth-child(5) {
                width: 105px;
                height: 105px;
                top: 80px;
                left: 813px;
                // animation: fifth 2s ease-in-out infinite alternate;
            }

            // @keyframes sixth {
            //     50% {
            //         width: 110px;
            //         height: 110px;
            //     }
            // }

            >div:nth-child(6) {
                width: 105px;
                height: 105px;
                top: 110px;
                left: 1100px;
                // animation: sixth 2s ease-in-out infinite alternate;
            }

            // @keyframes seventh {
            //     50% {
            //         width: 140px;
            //         height: 140px;
            //     }
            // }

            >div:nth-child(7) {
                width: 135px;
                height: 135px;
                top: 220px;
                left: 1365px;
                // animation: seventh 2s ease-in-out infinite alternate;
            }

            // @keyframes eighth {
            //     50% {
            //         width: 105px;
            //         height: 105px;
            //     }
            // }

            >div:nth-child(8) {
                width: 100px;
                height: 100px;
                top: 400px;
                left: 1295px;
                // animation: eighth 2s ease-in-out infinite alternate;
            }

            // @keyframes ninth {
            //     50% {
            //         width: 95px;
            //         height: 95px;
            //     }
            // }

            >div:nth-child(9) {
                width: 90px;
                height: 90px;
                top: 580px;
                left: 1380px;
                // animation: ninth 2s ease-in-out infinite alternate;
            }

            // @keyframes tenth {
            //     50% {
            //         width: 120px;
            //         height: 120px;
            //     }
            // }

            >div:nth-child(10) {
                width: 115px;
                height: 115px;
                top: 640px;
                left: 1121px;
                // animation: tenth 2s ease-in-out infinite alternate;
            }
        }
    }

    // 技术引领时代
    .technologyTime {
        height: 526px;
        background-color: skyblue;
        background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/技术引领时代.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        box-sizing: border-box;

        .ENtitle {
            padding-top: 60px;
        }

        .CNtitle {
            color: #fff;
        }

        .timeItem {
            display: flex;
            justify-content: space-around;
            margin-top: 60px;

            div:hover {
                p {
                    color: orange;
                }
            }

            div {
                cursor: pointer;

                img {
                    width: 63px;
                    height: 63px;
                    margin-bottom: 20px;
                }

                p {
                    color: #fff;
                    font-size: 33px;
                    font-weight: 500;
                    line-height: 71px;
                }
            }
        }
    }

    // 我们优势
    .advantage {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 80px;
        box-sizing: border-box;

        .ENtitle {
            margin-top: 40px;
        }

        .advantageMain {
            width: 90%;

            >div {
                display: flex;
                justify-content: space-around;

                .advantageItem {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    width: 385px;
                    height: 430px;
                    border: 1px solid #dadada;

                    img {
                        width: 100%;
                        height: 200px;
                        margin-bottom: 40px;
                    }

                    h2 {
                        font-size: 24px;
                        font-family: Source Han Sans CN;
                        font-weight: 500;
                        color: #202020;
                        line-height: 48px;
                    }

                    p {
                        width: 310px;
                        font-size: 16px;
                        font-family: Source Han Sans CN;
                        font-weight: 400;
                        color: #717171;
                        line-height: 31px;
                        text-align: left;
                    }
                }
            }
        }
    }

    // 服务流程
    .serviceProcess {
        width: 100%;
        height: 756px;
        background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/服务流程.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        box-sizing: border-box;
        padding-top: 60px;
        margin-bottom: 80px;

        .CNtitle {
            color: #ffffff;
        }
    }

    // 合作伙伴
    .partner {
        margin-bottom: 85px;

        img {
            width: 1400px;
            height: 410px;
        }
    }
}
</style>
