<template>
<div class="side" @mouseleave="hide">
    <div class="main">
        <div class="act" ref="act">
            <div v-if="isShow == 1">
                <div class="sideTitle">
                    <h2>企业客服</h2>
                    <p @click="close">X</p>
                </div>
                <div class="service">
                    <!-- <img src="../assets/image/code.png" alt=""> -->
                </div>
            </div>
            <div v-if="isShow == 2">
                <div class="sideTitle">
                    <h2>立即咨询</h2>
                    <p @click="close">X</p>
                </div>
                <el-form ref="form" :model="form">
                    <p>武汉泰铭恒创科技股份有限公司(027) 5976-5360</p>
                    <el-form-item>
                        <el-input type="textarea" v-model="form.desc" placeholder="*请在此输入咨询内容，我们会尽快与您联系。"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.name" placeholder="*姓名："></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.name" placeholder="*电话："></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-input v-model="form.name" placeholder="邮箱："></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary">立即创建</el-button>
                    </el-form-item>
                </el-form>
            </div>
        </div>
        <div class="isAct">
            <div @click="checkShow(1)">
                <!-- <img src="../assets/image/客服.png" alt=""> -->
                <p>企业客服</p>
            </div>
            <div @click="checkShow(2)">
                <!-- <img src="../assets/image/咨询.png" alt=""> -->
                <p>立即咨询</p>
            </div>
            <div class="toTop" @click="clicktop">
                <!-- <img src="../assets/image/回到顶部.png" alt=""> -->
                <p>Top</p>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            isShow: 0
        }
    },
    mounted() {},
    methods: {
        checkShow(num) {
            this.isShow = num
            this.$refs.act.style.display = "block"
        },
        close() {
            this.isShow = 0
            this.$refs.act.style.display = "none"
        },
        clicktop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        },
        hide() {
            document.querySelector(".act").style.display = "none"
        }
    }
};
</script>

<style lang="scss" scoped>
.sideTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    background-color: #ccc;
    padding: 0 20px;
    box-sizing: border-box;

    p {
        width: 40px;
        height: 40px;
        line-height: 40px;
        cursor: pointer;
    }

    .service {
        margin-bottom: 50px;
    }
}

.el-form {
    padding: 0 20px;

    p {
        margin: 20px 0;
        text-align: left;
    }
}

// @keyframes moveLeft {
//     from {
//         right: -55px;
//     }

//     to {
//         right: 0;
//     }
// }

// .side:hover {
//     animation-name: moveLeft;
//     animation-duration: 1s;
//     right: 0;
// }

.side {
    position: fixed;
    top: 50%;
    right: 00px;
    z-index: 9999;
    cursor: pointer;

    .main {
        display: flex;

        .act {
            width: 260px;
            background-color: #fff;
            display: none;

            >div {
                height: 100%;
            }
        }

        .isAct {
            >div {
                display: flex;
                flex-direction: column;
                justify-content: space-around;
                align-items: center;
                width: 80px;
                height: 80px;
                background: #FFFFFF;
                box-shadow: 0px 4px 12px 1px rgba(106, 106, 106, 0.29);
                border-radius: 4px;
                // margin-top: 10px;

                img {
                    width: 30px;
                    height: 30px;
                }

                p {
                    font-size: 12px;
                }
            }
        }
    }

}
</style>
