import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// 导入css重置样式
import "./assets/css/reset.css"

// 引入基础样式
import "./assets/css/base.css"

// 引入公共样式
import "./assets/css/common.css"

// 引入字体图标
import "./assets/fonts/iconfont.css"

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';

// // 引入 mavon-editor富文本编辑器
// import mavonEditor from 'mavon-editor'
// import 'mavon-editor/dist/css/index.css'
// // 挂载全局
// Vue.use(mavonEditor)


import './config/rem'


Vue.use(ElementUI);

Vue.config.productionTip = false

new Vue({
  el: '#app',
  router,
  store,
  render: h => h(App)
}).$mount('#app')
