<template>
<div class="nav">
    <div class="logo">
        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/timespark.png" alt="" />
        <div class="phone">
            <div>
                <!-- <i class="el-icon-phone-outline"></i> -->
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/商务热线.png" alt="">
                <span>商务热线</span>
            </div>
            <p>(027) 5976-5360</p>
        </div>
    </div>
    <div class="navList">
        <el-menu :default-active="activeIndex" :router="true" class="el-menu-demo" mode="horizontal" @select="handleSelect" active-text-color="orange" text-color="#303133">
            <el-menu-item index="/">首页</el-menu-item>
            <el-submenu index="/smartCity">
                <template slot="title">产品与服务</template>
                <el-menu-item index="/smartCity">智慧城市</el-menu-item>
                <el-menu-item index="/smartPolice">智慧公安</el-menu-item>
                <el-menu-item index="/smartTraffic">智慧交通</el-menu-item>
                <el-menu-item index="#">
                    <a href="http://www.smsspark.cn/index" target="_blank">短信服务网关</a>
                </el-menu-item>
                <el-menu-item index="#">
                    <a href="https://www.flyreport.cn/home" target="_blank">飞报大师</a>
                </el-menu-item>
            </el-submenu>
            <el-submenu index="3">
                <template slot="title">解决方案</template>
                <el-submenu index="3-1">
                    <template slot="title">行业解决方案</template>
                    <el-menu-item index="/energy">能源</el-menu-item>
                    <el-menu-item index="/community">社区</el-menu-item>
                    <el-menu-item index="/manufacturing">制造业</el-menu-item>
                    <el-menu-item index="/enterpriseServices">企业服务</el-menu-item>
                    <el-menu-item index="/fireControl">消防</el-menu-item>
                    <el-menu-item index="/business">电子商务</el-menu-item>
                </el-submenu>
                <el-submenu index="3-2">
                    <template slot="title">技术解决方案</template>
                    <el-menu-item index="/blockchain">区块链</el-menu-item>
                    <el-menu-item index="/Data">大数据</el-menu-item>
                    <el-menu-item index="/internet">物联网</el-menu-item>
                    <el-menu-item index="/AI">人工智能</el-menu-item>
                </el-submenu>
            </el-submenu>
            <el-menu-item index="/case">应用案例</el-menu-item>
            <el-submenu index="/companyIntroduction">
                <template slot="title">关于我们</template>
                <el-menu-item index="/companyIntroduction">公司介绍</el-menu-item>
                <el-menu-item index="/joinUS">加入我们</el-menu-item>
            </el-submenu>
        </el-menu>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            // 头部导航
            activeIndex: "/",
        };
    },
    created() {
        this.activeIndex = this.$route.path
    },
    methods: {
        // 头部导航切换
        handleSelect(key, keyPath) {
            console.log(key, keyPath);
        },
    },
};
</script>

<style lang="scss" scoped>
a {
    color: #303133;
    text-decoration: none;
}

.is-active {
    font-weight: 700;
}

.is-opened {
    // font-weight: bolder;
}

.nav {
    position: sticky !important;
    top: 0;
    left: 0;
    z-index: 9999 !important;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    background-color: #fff;
    padding: 0 5%;
    box-sizing: border-box;

    .logo {
        display: flex;
        align-items: center;
        height: 100px;

        img {
            width: 160px;
            height: 55px;
        }

        .phone {
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-left: 1px solid rgba($color: #d5d5d5, $alpha: 1);
            margin-left: 20px;
            padding-left: 20px;
            font-weight: 400;
            color: #000000;

            div {
                display: flex;
                box-sizing: border-box;
                margin-bottom: 10px;

                i {
                    font-size: 24px;
                    color: #fa5605;
                }

                img {
                    width: 20px;
                    height: 20px;
                }

                span {
                    margin-left: 10px;
                    font-size: 18px;
                    font-weight: 700;
                }
            }

            p {
                font-size: 20px;
                font-weight: 700;
            }
        }
    }

    .navList {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;

        .el-menu {
            display: flex;
            justify-content: space-around;
            align-items: center;
            border-bottom: none;
            height: 100%;

            li {
                height: 90%;
                line-height: 90px;
                display: flex;
                // justify-content: center;
                // align-items: center;
                font-size: 18px;

                ::v-deep .el-submenu__title {
                    font-size: 18px;
                }
            }

            ::v-deep .el-submenu__title {
                height: 90%;
                line-height: 90px;
            }
        }
    }
}
</style>
