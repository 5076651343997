<template>
<div class="footer">
    <!-- 联系我们 -->
    <div class="callMe">
        <p>立即联系泰铭恒创专家团队，为您提供解决方案</p>
        <el-button @click="checkShow(2)">
            联系我们
        </el-button>
    </div>
    <!-- 公司信息列举 -->
    <div class="InfoEnumeration">
        <div>
            <h2>关于我们</h2>
            <p>
                <a href="/companyIntroduction">
                    公司介绍
                </a>
            </p>
            <p>
                <a href="/joinUS">
                    加入我们
                </a>
            </p>
            <p>
                <a href="/case">
                    客户案例
                </a>
            </p>
        </div>
        <div>
            <h2>产品服务</h2>
            <p>
                <a href="/smartCity">
                    智慧城市
                </a>
            </p>
            <p>
                <a href="https://www.flyreport.cn/home" target="_blank">
                    飞报大师
                </a>
            </p>
            <p>
                <a href="/smartPolice">
                    智慧公安
                </a>
            </p>
            <p>
                <a href="/smartTraffic">
                    智慧交通
                </a>
            </p>
            <p>
                <a href="/SMSgateway">
                    短信网关
                </a>
            </p>
        </div>
        <div>
            <h2>行业解决方案</h2>
            <p>
                <a href="/smartCity">
                    政务场景
                </a>
            </p>
            <p>
                <a href="/energy">
                    能源场景
                </a>
            </p>
            <p>
                <a href="/community">
                    社区场景
                </a>
            </p>
            <p>
                <a href="/smartPolice">
                    公安场景
                </a>
            </p>
            <p>
                <a href="/manufacturing">
                    制造业场景
                </a>
            </p>
            <p>
                <a href="/business">
                    电子商务场景
                </a>
            </p>
            <p>
                <a href="#">
                    教育场景
                </a>
            </p>
            <p>
                <a href="/fireControl">
                    消防场景
                </a>
            </p>
            <p>
                <a href="/enterpriseServices">
                    企业服务场景
                </a>
            </p>
        </div>
        <div>
            <h2>技术解决方案</h2>
            <p>
                <a href="/blockchain">
                    区块链
                </a>
            </p>
            <p>
                <a href="/Data">
                    大数据
                </a>
            </p>
            <p>
                <a href="/internet">
                    物联网
                </a>
            </p>
            <p>
                <a href="/AI">
                    人工智能
                </a>
            </p>
        </div>
        <div>
            <h2>友情链接</h2>
            <p>
                <a href="https://www.51eagle.com/home" target="_blank"> 
                    千鹰云校
                </a>
            </p>
        </div>
        <div>
            <h2>联系我们</h2>
            <p>联系电话：(027) 5976-5360</p>
            <p>企业邮箱：biz@timespark.cn</p>
            <p>联系地址：武汉市东湖新技术开发区华师园一路光元科技园</p>
            <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/地址.png" alt="" class="address" />
        </div>
        <div class="QRcode">
            <div>
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/code.png" alt="" />
                <p>扫一扫关注公众号</p>
            </div>
            <div>
                <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/code.png" alt="" />
                <p>扫一扫联系客服</p>
            </div>
        </div>
    </div>
    <!-- 备案 -->
    <p class="record">
        武汉泰铭恒创信息技术股份有限公司 ©Copyright2017-2020 All Rights Reserved
        鄂ICP备17025230号
    </p>

    <!-- 侧边栏 -->
    <div class="side" @mouseleave="hide">
        <div class="main">
            <div class="act" ref="act">
                <div v-if="isShow == 1">
                    <div class="sideTitle">
                        <h2>企业客服</h2>
                        <p @click="close">X</p>
                    </div>
                    <div class="service">
                        <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/code.png" alt="">
                    </div>
                </div>
                <div v-if="isShow == 2">
                    <div class="sideTitle">
                        <h2>立即咨询</h2>
                        <p @click="close">X</p>
                    </div>
                    <el-form ref="form" :model="form">
                        <p>武汉泰铭恒创科技股份有限公司(027) 5976-5360</p>
                        <el-form-item>
                            <el-input type="textarea" v-model="form.desc" placeholder="*请在此输入咨询内容，我们会尽快与您联系。"></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="form.name" placeholder="*姓名："></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="form.phone" placeholder="*电话："></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-input v-model="form.email" placeholder="邮箱："></el-input>
                        </el-form-item>
                        <el-form-item>
                            <el-button type="primary">立即创建</el-button>
                        </el-form-item>
                    </el-form>
                </div>
            </div>
            <div class="isAct">
                <div @click="checkShow(1)">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/客服.png" alt="">
                    <p>企业客服</p>
                </div>
                <div @click="checkShow(2)">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/咨询.png" alt="">
                    <p>立即咨询</p>
                </div>
                <div class="toTop" @click="clicktop">
                    <img src="http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/回到顶部.png" alt="">
                    <p>Top</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    data() {
        return {
            form: {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            isShow: 0
        }
    },
    methods: {
        checkShow(num) {
            this.isShow = num
            this.$refs.act.style.display = "block"
        },
        close() {
            this.isShow = 0
            this.form = {
                name: '',
                region: '',
                date1: '',
                date2: '',
                delivery: false,
                type: [],
                resource: '',
                desc: ''
            },
            this.$refs.act.style.display = "none"
        },
        clicktop() {
            window.scrollTo({
                top: 0,
                behavior: "smooth"
            })
        },
        hide() {
            // document.querySelector(".act").style.display = "none"
        }
    }
};
</script>

<style lang="scss" scoped>
a {
    color: #ffffff;
    text-decoration: none
}

.footer {
    width: 100%;

    // 联系我们
    .callMe {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 8%;
        height: 170px;
        background-image: url(http://timespark-gw.oss-cn-hangzhou.aliyuncs.com/image/联系我们.png);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;

        p {
            font-size: 30px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            line-height: 61px;
        }

        .el-button {
            width: 224px;
            height: 58px;
            border: none;
            font-size: 24px;
            font-family: Source Han Sans CN;
            font-weight: 500;
            color: #ffffff;
            line-height: 31px;
            background: #fb5604;
        }
    }

    .InfoEnumeration {
        display: flex;
        justify-content: space-around;
        justify-content: space-between;
        box-sizing: border-box;
        padding: 35px 8% 20px 8%;
        background-color: #201d20;
        text-align: left;

        h2 {
            font-size: 18px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #ffffff;
            line-height: 40px;
        }

        p {
            font-size: 14px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #cccccc;
            line-height: 30px;
        }

        .address {
            width: 300px;
            height: 185px;
        }

        .QRcode {
            align-self: center;
            width: 450px;
            height: 260px !important;
            display: flex;
            justify-content: space-around;
            align-items: center;
            border-left: 1px solid rgba($color: #cccc, $alpha: 0.2);

            div {
                text-align: center;

                img {
                    width: 140px;
                    height: 140px;
                }

                p {
                    font-size: 20px;
                    font-family: Source Han Sans CN;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 51px;
                }
            }
        }
    }

    .record {
        background: #333333;
        font-size: 14px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #cccccc;
        line-height: 30px;
    }

    // 侧边栏
    .sideTitle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 40px;
        background-color: #ccc;
        padding: 0 20px;
        box-sizing: border-box;

        p {
            width: 40px;
            height: 40px;
            line-height: 40px;
            cursor: pointer;
        }

        .service {
            margin-bottom: 50px;
        }
    }

    .el-form {
        padding: 0 20px;

        p {
            margin: 20px 0;
            text-align: left;
        }
    }

    // @keyframes moveLeft {
    //     from {
    //         right: -55px;
    //     }

    //     to {
    //         right: 0;
    //     }
    // }

    // .side:hover {
    //     animation-name: moveLeft;
    //     animation-duration: 1s;
    //     right: 0;
    // }

    .side {
        position: fixed;
        top: 50%;
        right: 00px;
        z-index: 9999;
        cursor: pointer;

        .main {
            display: flex;

            .act {
                width: 260px;
                background-color: #fff;
                display: none;

                >div {
                    height: 100%;
                }
            }

            .isAct {
                >div {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-around;
                    align-items: center;
                    width: 80px;
                    height: 80px;
                    background: #FFFFFF;
                    box-shadow: 0px 4px 12px 1px rgba(106, 106, 106, 0.29);
                    border-radius: 4px;
                    // margin-top: 10px;

                    img {
                        width: 30px;
                        height: 30px;
                    }

                    p {
                        font-size: 12px;
                    }
                }
            }
        }

    }
}
</style>
